import SectionHeader from "../SectionHeader/SectionHeader";
import { useTranslation, Trans} from "react-i18next";

import Structure from '../Structure/Structure';
import Gallery from '../Gallery/Gallery';
import WavyBox from '../WavyBox/WavyBox';

import './AppSection.css';

function AppSection( props ) {
  const { title, bgimg, bgcol, color, elems } = props;
  const { t, i18n } = useTranslation();

  return (
      <section id={title} className="App-section" style={{ "--color": "var("+color+")", "--bgcol": "var("+bgcol+")" }}>
        <SectionHeader
          title={ t( title ) }
          bgimg={ bgimg }
          bgcol={ bgcol }
        />
        {
          elems.map((elem, e) =>
            elem.type === "structure" ?
              <Structure key={title + "structure" + e}/>
            :
            elem.type === "gallery" ?
              <Gallery key={title + "gallery" + e}/>
            :
            elem.type === "wavybox" ?
              <WavyBox key={"wavybox" + e}/>
            :
            elem.type === "img" ?
              <img className={elem.class} src={elem.src} key={title +  elem.type + e} />
            :
            elem.type === "trans" ?
              <p>
                <Trans
                  i18nKey={elem.text}
                  components={{ bold: <strong /> }}
                />
              </p>
            :
              <elem.type className={elem.class} key={title + elem.type + e}>
                { t( elem.text ) }
                { elem.children.map((child, c) => (
                  typeof child.type !== "undefined"
                  ?
                    child.type === "img"
                    ?
                      child.anchor === ""
                      ?
                        <img className={child.class} src={child.src} key={title +  child.type + c} />
                      :
                        <a href={child.anchor}>
                          <img className={child.class} src={child.src} key={title +  child.type + c} />
                        </a>
                    :
                    child.type === "ods"
                    ?
                      <img src={require("../../assets/images/ods/" + i18n.language + "/" + child.ods + ".jpg")} key={"ods" + c} />
                    :
                      <child.type key={title + child.type + c}>
                        { t( child.text ) }
                      </child.type>
                  :
                    <div>
                      {child.map((item, i)=>(
                        item.type === "trans" ?
                          <p>
                            <Trans
                              i18nKey={item.text}
                              components={{ bold: <strong /> }}
                            />
                          </p>
                        :
                        item.type === "img" ?
                          <img className={item.class} src={item.src} key={title +  item.type + c + "-" + i} />
                        :
                        <item.type key={title + item.type + c + "-" + i}>
                          { t( item.text ) }
                        </item.type>
                      ))}
                    </div>
                ))}
              </elem.type>
          )
        }
        
      </section>
    );
  }
  
  export default AppSection;
