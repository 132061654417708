import joanplanes from '../assets/images/structure/patronage/joanplanes.jpg';
import xaviservat from '../assets/images/structure/patronage/xaviservat.jpg';
import eloiplanes from '../assets/images/structure/patronage/eloiplanes.jpg';
import brucebrooks from '../assets/images/structure/patronage/brucebrooks.jpg';
import flaviamalet from '../assets/images/structure/patronage/flaviamalet.jpg';
import carlacoloma from '../assets/images/structure/patronage/carlacoloma.jpg';
import davidvilla from '../assets/images/structure/patronage/davidvilla.jpg';

import victorantich from '../assets/images/structure/members/victorantich.jpg';
import evabolea from '../assets/images/structure/members/evabolea.jpg';
import josemartinez from '../assets/images/structure/members/josemartinez.jpg';
import mariasaavedra from '../assets/images/structure/members/mariasaavedra.jpg';

import jillrooks from '../assets/images/structure/influencers/jillrooks.jpg';
import jorgeholger from '../assets/images/structure/influencers/jorgeholger.jpg';
import stephanefigueroa from '../assets/images/structure/influencers/stephanefigueroa.jpg';
import rodrigosorrilha from '../assets/images/structure/influencers/rodrigosorrilha.jpg';
import jeanpierrepellicia from '../assets/images/structure/influencers/jeanpierrepellicia.jpg';
import simonemerwe from '../assets/images/structure/influencers/simonemerwe.jpg';
import valentinatripp from '../assets/images/structure/influencers/valentinatripp.jpg';
import cindymirakeer from '../assets/images/structure/influencers/cindymirakeer.jpg';

const StructureData = [
    {
        "title": "aboutus.structure.patronage.title",
        "text": "",
        "elems": [
            {
                "img":joanplanes,
                "name":"aboutus.structure.patronage.joanplanes.name",
                "title":"aboutus.structure.patronage.joanplanes.title",
                "class":""
            },
            {
                "img":xaviservat,
                "name":"aboutus.structure.patronage.xaviservat.name",
                "title":"aboutus.structure.patronage.xaviservat.title",
                "class":""
            },
            {
                "img":eloiplanes,
                "name":"aboutus.structure.patronage.eloiplanes.name",
                "title":"aboutus.structure.patronage.eloiplanes.title",
                "class":""
            },
            {
                "img":brucebrooks,
                "name":"aboutus.structure.patronage.brucebrooks.name",
                "title":"aboutus.structure.patronage.brucebrooks.title",
                "class":""
            },
            {
                "img":flaviamalet,
                "name":"aboutus.structure.patronage.flaviamalet.name",
                "title":"aboutus.structure.patronage.flaviamalet.title",
                "class":""
            },
            {
                "img":carlacoloma,
                "name":"aboutus.structure.patronage.carlacoloma.name",
                "title":"aboutus.structure.patronage.carlacoloma.title",
                "class":""
            },
            {
                "img":davidvilla,
                "name":"aboutus.structure.patronage.davidvilla.name",
                "title":"aboutus.structure.patronage.davidvilla.title",
                "class":""
            }
        ]
    },
    {
        "title": "aboutus.structure.members.title",
        "text": "",
        "elems": [
            {
                "img":victorantich,
                "name":"aboutus.structure.members.victorantich.name",
                "title":"aboutus.structure.members.victorantich.title",
                "class":""
            },
            {
                "img":evabolea,
                "name":"aboutus.structure.members.evabolea.name",
                "title":"aboutus.structure.members.evabolea.title",
                "class":""
            },
            {
                "img":josemartinez,
                "name":"aboutus.structure.members.josemartinez.name",
                "title":"aboutus.structure.members.josemartinez.title",
                "class":""
            },
            {
                "img":mariasaavedra,
                "name":"aboutus.structure.members.mariasaavedra.name",
                "title":"aboutus.structure.members.mariasaavedra.title",
                "class":""
            }
        ]
    },
    {
        "title": "aboutus.structure.influencers.title",
        "text": "aboutus.structure.influencers.p1",
        "elems": [
            {
                "img":jillrooks,
                "name":"aboutus.structure.influencers.jillrooks.name",
                "title":"aboutus.structure.influencers.jillrooks.title",
                "class":""
            },
            {
                "img":jorgeholger,
                "name":"aboutus.structure.influencers.jorgeholger.name",
                "title":"aboutus.structure.influencers.jorgeholger.title",
                "class":""
            },
            {
                "img":stephanefigueroa,
                "name":"aboutus.structure.influencers.stephanefigueroa.name",
                "title":"aboutus.structure.influencers.stephanefigueroa.title",
                "class":""
            },
            {
                "img":rodrigosorrilha,
                "name":"aboutus.structure.influencers.rodrigosorrilha.name",
                "title":"aboutus.structure.influencers.rodrigosorrilha.title",
                "class":""
            },
            {
                "img":simonemerwe,
                "name":"aboutus.structure.influencers.simonemerwe.name",
                "title":"aboutus.structure.influencers.simonemerwe.title",
                "class":""
            },
            {
                "img":valentinatripp,
                "name":"aboutus.structure.influencers.valentinatripp.name",
                "title":"aboutus.structure.influencers.valentinatripp.title",
                "class":""
            },
            {
                "img":cindymirakeer,
                "name":"aboutus.structure.influencers.cindymirakeer.name",
                "title":"aboutus.structure.influencers.cindymirakeer.title",
                "class":""
            },
            {
                "img":jeanpierrepellicia,
                "name":"aboutus.structure.influencers.jeanpierrepellicia.name",
                "title":"aboutus.structure.influencers.jeanpierrepellicia.title",
                "class":""
            }
        ]
    }
];

export default StructureData;