import './SectionHeader.css';

function SectionHeader( props ) {
    const { title, bgimg } = props;

    return (
        <h2 className="Section-header" style={{ "--bgimg": "var("+bgimg+")"}}>{ title }</h2>
    );
  }
  
  export default SectionHeader;
