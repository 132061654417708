import whoarewe from "../assets/images/body/whoarewe3.jpg";
import legacy from "../assets/images/body/legacy.jpg";
import missionvalues from "../assets/images/body/missionvalues.jpg";
import putapool from "../assets/images/body/putapool.jpg";
import fluidraday from "../assets/images/body/fluidraday.jpg";
import waterlife1 from "../assets/images/body/waterlife1.jpg";
// import waterlife2 from "../assets/images/body/waterlife2.jpg";
import benefits from "../assets/images/body/benefits.jpg";

import pap from "../assets/images/solidarypool/pap.png";
import ssc from "../assets/images/solidarypool/ssc.png";
import fld from "../assets/images/solidarypool/fld.png";

import foc from "../assets/images/entities/foc.png";
import osv from "../assets/images/entities/osv.png";
import pmc from "../assets/images/entities/pmc.png";
import fcc from "../assets/images/entities/fcc.png";
import fjm from "../assets/images/entities/fjm.png";

import sjd from "../assets/images/entities/sjd.png";
import kag from "../assets/images/entities/kag25.png";
import nrsi from "../assets/images/entities/nsri.png";
import cram from "../assets/images/entities/cram.png";

const Data = [
    // {
    //     "title": "intro",
    //     "bgimg": "--intro",
    //     "bgcol": "--none",
    //     "color": "--none",
    //     "elems": [
    //         // {
    //         //     "type":"wavybox",
    //         //     "text":"",
    //         //     "class":"",
    //         //     "children":[]
    //         // }
    //     ]
    // },
    {
        "title": "aboutus.whoarewe.title",
        "bgimg": "--intro",
        // "bgimg": "--whoarewe",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"h3",
                "text":"aboutus.whoarewe.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"",
                "children":[
                    [
                        {
                            "type":"trans",
                            "text":"aboutus.whoarewe.p1",
                            "class":"",
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.whoarewe.p2",
                            "class":"",
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.whoarewe.p3",
                            "class":"",
                        }
                    ],
                    [
                        {
                            "type":"img",
                            "src":whoarewe,
                            "class":"",
                        }
                    ]
                ]
            },
            {
                "type":"div",
                "text":"",
                "class":"ods",
                "children":[
                    {
                        "type":"ods",
                        "ods": "ods2"
                    },
                    {
                        "type":"ods",
                        "ods": "ods3"
                    },
                    {
                        "type":"ods",
                        "ods": "ods4"
                    },
                    {
                        "type":"ods",
                        "ods": "ods6"
                    },
                    {
                        "type":"ods",
                        "ods": "ods10"
                    },
                    {
                        "type":"ods",
                        "ods": "ods11"
                    },
                    {
                        "type":"ods",
                        "ods": "ods14"
                    },
                    {
                        "type":"ods",
                        "ods": "ods17"
                    }
                ]
            }
            // {
            //     "type":"h3",
            //     "text":"aboutus.whoarewe.subtitle2",
            //     "class":"",
            //     "children":[]
            // },
            // {
            //     "type":"trans",
            //     "text":"aboutus.whoarewe.p4",
            //     "class":"",
            //     "children":[]
            // },
            
        ]
    },
    {
        "title": "aboutus.legacy.title",
        "bgimg": "--legacy",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"h3",
                "text":"aboutus.legacy.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"",
                "children":[
                    [
                        {
                            "type":"img",
                            "src":legacy,
                            "class":"",
                        }
                    ],
                    [
                        {
                            "type":"trans",
                            "text":"aboutus.legacy.p1",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.legacy.p2",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.legacy.p3",
                            "class":"",
                            "children":[]
                        }
                    ]
                ]
            },
        ]
    },
    {
        "title": "aboutus.missionvalues.title",
        "bgimg": "--missionvalues",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"div",
                "text":"",
                "class":"",
                "children":[
                    [
                        {
                            "type":"h3",
                            "text":"aboutus.missionvalues.subtitle1",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p1",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p2",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"h3",
                            "text":"aboutus.missionvalues.subtitle2",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p3",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p4",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p5",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"aboutus.missionvalues.p6",
                            "class":"",
                            "children":[]
                        }
                    ],
                    [
                        {
                            "type":"img",
                            "src":missionvalues,
                            "class":"",
                        }
                    ]
                ]
            },
        ]
    },
    {
        "title": "aboutus.structure.title",
        "bgimg": "--structure",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"structure",
                "text":"",
                "class":"",
                "children":[]
            }
        ]
    },
    {
        "title": "ourpillars.solidarypool.title",
        "bgimg": "--solidarypool",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.solidarypool.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.solidarypool.p1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.solidarypool.p2",
                "class":"",
                "children":[]
            },
            {
                "type":"h3",
                "text":"ourpillars.solidarypool.subtitle2",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"centered wider",
                "children":[
                    {
                        "type":"img",
                        "src":pap,
                        "class":"",
                        "anchor":"#ourpillars.putapool.title"
                    },
                    {
                        "type":"img",
                        "src":ssc,
                        "class":"",
                        "anchor":"#ourpillars.ssc.title"
                    },
                    {
                        "type":"img",
                        "src":fld,
                        "class":"",
                        "anchor":"#ourpillars.fluidraday.title"
                    }
                ]
            }
        ]
    },
    {
        "title": "ourpillars.putapool.title",
        "bgimg": "--putapool",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.putapool.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"",
                "children":[
                    [
                        {
                            "type":"img",
                            "src":putapool,
                            "class":"",
                        }
                    ],
                    [
                        {
                            "type":"trans",
                            "text":"ourpillars.putapool.p1",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"ourpillars.putapool.p2",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"ourpillars.putapool.p3",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"ourpillars.putapool.p4",
                            "class":"",
                            "children":[]
                        }
                    ]
                ]
            },
            // {
            //     "type":"trans",
            //     "text":"ourpillars.putapool.p1",
            //     "class":"",
            //     "children":[]
            // },
            // {
            //     "type":"trans",
            //     "text":"ourpillars.putapool.p2",
            //     "class":"",
            //     "children":[]
            // },
            // {
            //     "type":"trans",
            //     "text":"ourpillars.putapool.p3",
            //     "class":"",
            //     "children":[]
            // },
            // {
            //     "type":"trans",
            //     "text":"ourpillars.putapool.p4",
            //     "class":"",
            //     "children":[]
            // }
        ]
    },
    {
        "title": "ourpillars.ssc.title",
        "bgimg": "--ssc",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.ssc.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.ssc.p1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.ssc.p2",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.ssc.p3",
                "class":"",
                "children":[]
            }
        ]
    },
    {
        "title": "ourpillars.fluidraday.title",
        "bgimg": "--fluidraday",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.fluidraday.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.fluidraday.p1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.fluidraday.p2",
                "class":"",
                "children":[]
            },
            {
                "type":"img",
                "src":fluidraday,
                "class":"",
            },
            {
                "type":"trans",
                "text":"ourpillars.fluidraday.p3",
                "class":"",
                "children":[]
            },
            {
                "type":"ul",
                "text":"null",
                "class":"",
                "children":[
                    {
                        "type":"li",
                        "text":"ourpillars.fluidraday.ul1",
                        "class":"",
                        "children":[]
                    },
                    {
                        "type":"li",
                        "text":"ourpillars.fluidraday.ul2",
                        "class":"",
                        "children":[]
                    },
                    {
                        "type":"li",
                        "text":"ourpillars.fluidraday.ul3",
                        "class":"",
                        "children":[]
                    }
                ],
            },
            {
                "type":"trans",
                "text":"ourpillars.fluidraday.p4",
                "class":"",
                "children":[]
            }
        ]
    },
    {
        "title": "ourpillars.waterlife.title",
        "bgimg": "--waterlife",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.waterlife.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"columns",
                "children":[
                    [
                        {
                            "type":"trans",
                            "text":"ourpillars.waterlife.p1",
                            "class":"",
                            "children":[]
                        },
                        {
                            "type":"trans",
                            "text":"ourpillars.waterlife.p2",
                            "class":"",
                            "children":[]
                        }
                    ],
                    [
                        {
                            "type":"img",
                            "src":waterlife1,
                            "class":"",
                        },
                        // {
                        //     "type":"img",
                        //     "src":waterlife2,
                        //     "class":"",
                        // }
                    ]
                ]
            }
        ]
    },
    {
        "title": "ourpillars.culturemusic.title",
        "bgimg": "--culturemusic",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.culturemusic.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.culturemusic.p1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.culturemusic.p2",
                "class":"",
                "children":[]
            },
            {
                "type":"h3",
                "text":"ourpillars.culturemusic.subtitle2",
                "class":"",
                "children":[]
            },
            {
                "type":"div",
                "text":"",
                "class":"centered wider",
                "children":[
                    {
                        "type":"img",
                        "src":foc,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":osv,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":pmc,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":fcc,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":fjm,
                        "class":"",
                        "anchor":""
                    }
                ]
            }
        ]
    },
    {
        "title": "ourpillars.otherprojects.title",
        "bgimg": "--otherprojects",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"h3",
                "text":"ourpillars.otherprojects.subtitle1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.otherprojects.p1",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.otherprojects.p2",
                "class":"",
                "children":[]
            },
            {
                "type":"trans",
                "text":"ourpillars.otherprojects.p3",
                "class":"",
                "children":[]
            },
            {
                "type":"img",
                "src":benefits,
                "class":"",
            },
            {
                "type":"trans",
                "text":"ourpillars.otherprojects.p4",
                "class":"",
                "children":[]
            },
            // {
            //     "type":"trans",
            //     "text":"ourpillars.otherprojects.p5",
            //     "class":"",
            //     "children":[]
            // },
            {
                "type":"trans",
                "text":"ourpillars.otherprojects.p6",
                "class":"",
                "children":[]
            }
        ]
    },
    {
        "title": "ourpillars.entities.title",
        "bgimg": "--entities",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"div",
                "text":"",
                "class":"centered",
                "children":[
                    
                    {
                        "type":"img",
                        "src":sjd,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":kag,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":nrsi,
                        "class":"",
                        "anchor":""
                    },
                    {
                        "type":"img",
                        "src":cram,
                        "class":"",
                        "anchor":""
                    }
                    
                ]
            }
        ]
    },
    {
        "title": "menu.gallery",
        "bgimg": "--gallery",
        "bgcol": "--white",
        "color": "--main-color",
        "elems": [
            {
                "type":"gallery",
                "text":"",
                "class":"",
                "children":[]
            }
        ]
    },
    {
        "title": "contactus.title",
        "bgimg": "--contact",
        "bgcol": "--main-color",
        "color": "--white",
        "elems": [
            {
                "type":"h3",
                "text":"contactus.wewillhelp",
                "class":"",
                "children":[]
            },
            {
                "type":"p",
                "text":"contactus.holamail",
                "class":"email",
                "children":[]
            },
            {
                "type":"p",
                "text":"contactus.phone",
                "class":"phone",
                "children":[]
            },
            {
                "type":"p",
                "text":"contactus.address",
                "class":"address",
                "children":[]
            },
        ]
    }
];

export default Data;