import logo from '../../assets/images/white_logo.png';
import { useTranslation } from "react-i18next";
import './AppHeader.css';

function AppHeader() {
    const { t } = useTranslation();

    function handlePrevent(e) {
      e.preventDefault();
      console.log('You clicked a menu item.');
    }
  
    return (
      <div className="App-header">
        <a href="#"><img src={logo} className="App-header-logo" alt="logo" /></a>
        <ul className="App-header-menu">
          <li>
            <a href="#" onClick={handlePrevent}>
              {t("menu.aboutus")}
            </a>
            <ul>
              <li><a href="#aboutus.whoarewe.title">{t("aboutus.whoarewe.title")}</a></li>    
              <li><a href="#aboutus.legacy.title">{t("aboutus.legacy.title")}</a></li>    
              <li><a href="#aboutus.missionvalues.title">{t("aboutus.missionvalues.title")}</a></li>    
              <li><a href="#aboutus.structure.title">{t("aboutus.structure.title")}</a></li>    
            </ul>
          </li>
          <li>
            <a href="#" onClick={handlePrevent}>
              {t("menu.ourpillars")}
            </a>
            <ul>
              <li><a href="#ourpillars.solidarypool.title">{t("ourpillars.solidarypool.title")}</a></li>    
              <li><a href="#ourpillars.putapool.title">{t("ourpillars.putapool.title")}</a></li>    
              <li><a href="#ourpillars.ssc.title">{t("ourpillars.ssc.title")}</a></li>    
              <li><a href="#ourpillars.fluidraday.title">{t("ourpillars.fluidraday.title")}</a></li>    
            </ul>
          </li>
          <li><a href="#menu.gallery">{t("menu.gallery")}</a></li>
          <li>
            <a href="#" onClick={handlePrevent}>
              {t("menu.archive")}
            </a>
            <ul>
              <li><a target="_blank" rel="noreferrer" href="/estatuts.pdf">{t("archive.statutes")}</a></li>    
              <li><a target="_blank" rel="noreferrer" href={t("archive.memory2022pdf")}>{t("archive.memory2022")}</a></li>    
              <li><a target="_blank" rel="noreferrer" href={t("archive.memory2023pdf")}>{t("archive.memory2023")}</a></li>    
            </ul>
          </li>
          <li><a href="#contactus.title">{t("menu.contact")}</a></li>
        </ul>
      </div>
    );
  }
  
  export default AppHeader;
