import React from "react";
import { useTranslation } from "react-i18next";

import './App.css';

import Data from'./data/data';
import AppHeader from './components/AppHeader/AppHeader';
import AppSection from './components/AppSection/AppSection';

function App() {
  const {i18n} = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log("The language is: " + i18n.language)
  };

  return (
    <div className="App">
      <header>
        <AppHeader />
        <select
          className="languages"
          defaultValue={"es"}
          onChange={(e)=>changeLanguage(e.target.value)}
        >
          <option value="es">ES</option>
          <option value="ca">CA</option>
          <option value="en">EN</option>
          <option value="fr">FR</option>
          <option value="pt">PT</option>
          <option value="it">IT</option>
        </select>
      </header>
      <main>
        {Data.map((item, i)=>(
          <AppSection
            key= { i }
            title= { item.title }
            bgimg= { item.bgimg }
            bgcol= { item.bgcol }
            color= { item.color }
            elems= { item.elems }
          />
        ))}
      </main>
    </div>
  );
}

export default App;
