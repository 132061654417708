// import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import './Gallery.css';

// Put a Pool pics
import Pic01 from "../../assets/images/gallery/pic01.jpg";
import Pic02 from "../../assets/images/gallery/pic02.jpg";
import Pic03 from "../../assets/images/gallery/pic03.jpg";
import Pic04 from "../../assets/images/gallery/pic04.jpg";
import Pic05 from "../../assets/images/gallery/pic05.jpg";
import Pic06 from "../../assets/images/gallery/pic06.jpg";
import Pic07 from "../../assets/images/gallery/pic07.jpg";
import Pic08 from "../../assets/images/gallery/pic08.jpg";
import Pic09 from "../../assets/images/gallery/pic09.jpg";
import Pic10 from "../../assets/images/gallery/pic10.jpg";
import Pic11 from "../../assets/images/gallery/pic11.jpg";
import Pic12 from "../../assets/images/gallery/pic12.jpg";
import Pic13 from "../../assets/images/gallery/pic13.jpg";
import Pic14 from "../../assets/images/gallery/pic14.jpg";
import Pic15 from "../../assets/images/gallery/pic15.jpg";
import Pic16 from "../../assets/images/gallery/pic16.jpg";
import Pic17 from "../../assets/images/gallery/pic17.jpg";
import Pic18 from "../../assets/images/gallery/pic18.jpg";
import Pic19 from "../../assets/images/gallery/pic19.jpg";
import Pic20 from "../../assets/images/gallery/pic20.jpg";
import Pic21 from "../../assets/images/gallery/pic21.jpg";
import Pic22 from "../../assets/images/gallery/pic22.jpg";
import Pic23 from "../../assets/images/gallery/pic23.jpg";
import Pic24 from "../../assets/images/gallery/pic24.jpg";
import Pic25 from "../../assets/images/gallery/pic25.jpg";
import Pic26 from "../../assets/images/gallery/pic26.jpg";
import Pic27 from "../../assets/images/gallery/pic27.jpg";
import Pic28 from "../../assets/images/gallery/pic28.jpg";
import Pic29 from "../../assets/images/gallery/pic29.jpg";
import Pic30 from "../../assets/images/gallery/pic30.jpg";
import Pic31 from "../../assets/images/gallery/pic31.jpg";
import Pic32 from "../../assets/images/gallery/pic32.jpg";
import Pic33 from "../../assets/images/gallery/pic33.jpg";
import Pic34 from "../../assets/images/gallery/pic34.jpg";
import Pic35 from "../../assets/images/gallery/pic35.jpg";

const images = [
  {
    original: Pic01,
    thumbnail: Pic01,
    originalAlt: "Put a Pool - Asociación JAS, Sáhara Occidental",
    originalTitle: "Put a Pool - Asociación JAS, Sáhara Occidental",
    description: "Put a Pool - Asociación JAS, Sáhara Occidental"
  },
  {
    original: Pic02,
    thumbnail: Pic02,
    originalAlt: "Put a Pool - Associació Ciutat Vella, Barcelona",
    originalTitle: "Put a Pool - Associació Ciutat Vella, Barcelona",
    description: "Put a Pool - Associació Ciutat Vella, Barcelona"
  },
  {
    original: Pic03,
    thumbnail: Pic03,
    originalAlt: "Put a Pool - Associació Ciutat Vella, Barcelona",
    originalTitle: "Put a Pool - Associació Ciutat Vella, Barcelona",
    description: "Put a Pool - Associació Ciutat Vella, Barcelona"
  },
  {
    original: Pic04,
    thumbnail: Pic04,
    originalAlt: "Put a Pool - Fundació Pere Tarrés",
    originalTitle: "Put a Pool - Fundació Pere Tarrés",
    description: "Put a Pool - Fundació Pere Tarrés"
  },
  {
    original: Pic05,
    thumbnail: Pic05,
    originalAlt: "Put a Pool - Fundación Valora, Colombia",
    originalTitle: "Put a Pool - Fundación Valora, Colombia",
    description: "Put a Pool - Fundación Valora, Colombia"
  },
  {
    original: Pic06,
    thumbnail: Pic06,
    originalAlt: "Put a Pool - Fundación Valora, Colombia",
    originalTitle: "Put a Pool - Fundación Valora, Colombia",
    description: "Put a Pool - Fundación Valora, Colombia"
  },
  {
    original: Pic07,
    thumbnail: Pic07,
    originalAlt: "Put a Pool - The Emiliani Project, Colombia",
    originalTitle: "Put a Pool - The Emiliani Project, Colombia",
    description: "Put a Pool - The Emiliani Project, Colombia"
  },
  {
    original: Pic08,
    thumbnail: Pic08,
    originalAlt: "Put a Pool - NSRI, Sudáfrica",
    originalTitle: "Put a Pool - NSRI, Sudáfrica",
    description: "Put a Pool - NSRI, Sudáfrica"
  },
  {
    original: Pic09,
    thumbnail: Pic09,
    originalAlt: "Put a Pool - NSRI, Sudáfrica",
    originalTitle: "Put a Pool - NSRI, Sudáfrica",
    description: "Put a Pool - NSRI, Sudáfrica"
  },
  {
    original: Pic10,
    thumbnail: Pic10,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic11,
    thumbnail: Pic11,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic12,
    thumbnail: Pic12,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic13,
    thumbnail: Pic13,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic14,
    thumbnail: Pic14,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic15,
    thumbnail: Pic15,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic16,
    thumbnail: Pic16,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic17,
    thumbnail: Pic17,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic18,
    thumbnail: Pic18,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic19,
    thumbnail: Pic19,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic20,
    thumbnail: Pic20,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic21,
    thumbnail: Pic21,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic22,
    thumbnail: Pic22,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic23,
    thumbnail: Pic23,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic24,
    thumbnail: Pic24,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic25,
    thumbnail: Pic25,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic26,
    thumbnail: Pic26,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic27,
    thumbnail: Pic27,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic28,
    thumbnail: Pic28,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic29,
    thumbnail: Pic29,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic30,
    thumbnail: Pic30,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic31,
    thumbnail: Pic31,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic32,
    thumbnail: Pic32,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic33,
    thumbnail: Pic33,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic34,
    thumbnail: Pic34,
    originalAlt: "",
    originalTitle: "",
    description: ""
  },
  {
    original: Pic35,
    thumbnail: Pic35,
    originalAlt: "",
    originalTitle: "",
    description: ""
  }
];

function Gallery() {
    // const { t } = useTranslation();
  
    return (
      <ImageGallery additionalClass="Gallery" items={images} />
    );
  }
  
  export default Gallery;
