import StructureData from "../../data/structure";
import { useTranslation } from "react-i18next";
import './Structure.css';

function Structure() {
  const { t } = useTranslation();

  console.log(StructureData[0].elems[0].img);

  return (
      <div className="Structure">
        {StructureData.map(( item, i )=>(
          <div key={"structure"+i}>
            <h2 className="StructureHeader">{ t( item.title ) }</h2>
            {item.text === ""
            ?
              null
            :
              <p>{ t( item.text ) }</p>
            }
            <div className="FigureBox">
              {item.elems.map(( elem, e )=>(
                <figure key={"figure"+e}>
                  <img
                    src={ elem.img }
                    alt={t( elem.text )}
                  />
                  <figcaption>
                    <span>{ t( elem.name ) }</span>
                    <span>{ t( elem.title ) }</span>
                  </figcaption>
                </figure>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default Structure;
