import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nES from './i18n/es.json';
import i18nCA from './i18n/ca.json';
import i18nEN from './i18n/en.json';
import i18nFR from './i18n/fr.json';
import i18nPT from './i18n/pt.json';
import i18nIT from './i18n/it.json';

i18n
  .use(initReactI18next)
  .init({
    "resources": {
        "es": i18nES,
        "ca": i18nCA,
        "en": i18nEN,
        "fr": i18nFR,
        "pt": i18nPT,
        "it": i18nIT,

    },
    "lng": "es",
    "fallbackLng": "es",
    "interpolation": {
        "escapeValue": false,
        "joinArrays": ''
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
