import './WavyBox.css';

function WavyBox() {

    return (
        <div class="wavy">
            <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style={{"stroke": "none", "fill":"#001A70"}}></path>
            </svg>
        </div>
    );
  }
  
  export default WavyBox;
